

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule, Customer, hotelConverter, customerConverter } from '@/store/app'
import firebase from 'firebase/app';
import 'firebase/firestore';
import fs = firebase.firestore;
const db = firebase.firestore();

import BaseButton from '@/components/BaseButton.vue'
import Panel from '@/components/Panel.vue'
import { dateToFormatString } from '@/dateToFormatString'
@Component({
  components: {
    BaseButton,
    Panel
  },
})
export default class CustomerView extends Vue {
  private image = '';
  private get customer(){
    return AppModule.customer;
  }
  mounted() {
    firebase.auth().onAuthStateChanged(user => {
      AppModule.setCurrentUser(user);
      if (user) {
        this.getCustomClaimRole();
        db.collection('hotels').doc(user.uid).collection("customers").doc(this.$route.params.id).withConverter(customerConverter).get().then((customer)=>{
          AppModule.setCustomer(customer.data()!);
          firebase.storage().ref().child(`/ids/${customer.ref.parent.parent!.id}/${customer.id}`).getDownloadURL().then((v)=>{
            this.image = v;
          }).catch((e)=>{
            this.image = require('@/assets/no-image.png');
          });
        });
      }else{
        this.$router.push('/manage/signin');
      }
    });
  }

  private checkIn(isCheckedIn: boolean){
    db.collection('hotels').doc(AppModule.currentUser!.uid).collection("customers").doc(this.$route.params.id).withConverter(customerConverter).update({isCheckedIn: isCheckedIn}).then(()=>{
      if(isCheckedIn){
        this.$notify(this.$t('notification.successCheckin'));
        firebase.analytics().logEvent('checkin');
      }else{
        this.$notify(this.$t('notification.cancelCheckin'));
        firebase.analytics().logEvent('cancel_checkin');
      }
      db.collection('hotels').doc(AppModule.currentUser!.uid).collection("customers").doc(this.$route.params.id).withConverter(customerConverter).get().then((customer)=>{
        AppModule.setCustomer(customer.data()!);
      });
    });
  }

  private destroy(){
    if(confirm(this.$t('confirm.delete').toString())){
      db.collection('hotels').doc(AppModule.currentUser!.uid).collection("customers").doc(this.$route.params.id).delete().then(()=>{
        this.$notify(this.$t('notification.delete'));
        this.$router.push('/manage');
      });
    }
  }

  private async getCustomClaimRole() {
    await firebase.auth().currentUser!.getIdToken(true);
    const decodedToken = await firebase.auth().currentUser!.getIdTokenResult();
    if(!decodedToken.claims.stripeRole)this.$router.push('/manage/payment');
  }

  private getDateString(date: Date){
    return dateToFormatString(date, this.$t('dateFormat').toString());
  }
}
